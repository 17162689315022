import * as React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import SEO from "../components/seo"
import './activity.css'
import {AnchorLink} from "gatsby-plugin-anchor-links";

function Activity({ pageContext }) {
    const { post } = pageContext
    return (

        <Layout>
            <SEO title={post.frontmatter.title} description={post.frontmatter.description}/>

            <div className="head-post">
                <GatsbyImage
                    image  = {getImage(post.frontmatter.image.childImageSharp.gatsbyImageData)}
                    alt = {post.frontmatter.imageAlt}
                    loading ='lazy'
                    className ='img-header-post'
                    imgClassName = 'img-header'
                />
                <div id="setka"></div>
            </div>
            <h1 className={'header-post ' + post.frontmatter.category}>{post.frontmatter.title}</h1>
            <div className="container">
                <MDXRenderer>{post.body}</MDXRenderer>
                <div className={'but-up'}>
                    <AnchorLink to={'/' + post.frontmatter.category + `/` + post.frontmatter.slug + '#main'}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-square-up" role="img"
                             xmlns="http://www.w3.org/2000/svg" width="36" height='36' viewBox="0 0 448 512">
                            <path fill="currentColor"
                                  d="M0 432V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48zm207.029-281.941L71.515 285.573c-9.373 9.373-9.373 24.569 0 33.941l16.971 16.971c9.373 9.373 24.569 9.373 33.941 0L224 234.912l101.573 101.574c9.373 9.373 24.569 9.373 33.941 0l16.971-16.971c9.373-9.373 9.373-24.569 0-33.941L240.971 150.059c-9.373-9.373-24.569-9.373-33.942 0z"
                                  className=""></path>
                        </svg>
                    </AnchorLink>
                </div>
            </div>

        </Layout>
    )
}

export default Activity